var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-space-y-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 overflow-auto",staticStyle:{"min-height":"300px","max-height":"400px"}},[_c('table',{staticClass:"table ui-table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"required",staticStyle:{"width":"23%"}},[_vm._v(" "+_vm._s(_vm.$t("repairProductProfile.product"))+" ")]),_c('th',{staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.$t("repairProductProfile.serial"))+" ")]),_c('th',{staticStyle:{"width":"10%"}},[_vm._v(" "+_vm._s(_vm.$t("serviceTicket.uom"))+" ")]),_c('th',{staticClass:"required",staticStyle:{"width":"10%"}},[_vm._v(" "+_vm._s(_vm.$t("repairProductProfile.qty"))+" ")]),_c('th',{staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.$t("repairProductProfile.remarks"))+" ")]),_c('th',{staticStyle:{"width":"3%"}},[_c('a',{staticClass:"text-primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addNewItem.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-plus"})])])])]),_c('tbody',_vm._l((_vm.model.product_detail_list),function(item,index){return _c('tr',{key:index},[_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.product_name),expression:"item.product_name"}],staticClass:"form-control only-border-bottom",class:{
                                    'is-invalid': _vm.errors.has(
                                        'product_detail_list.' +
                                            index +
                                            '.product_name'
                                    )
                                },attrs:{"type":"text"},domProps:{"value":(item.product_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "product_name", $event.target.value)}}}),(
                                    _vm.errors.has(
                                        'product_detail_list.' +
                                            index +
                                            '.product_name'
                                    )
                                )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first( "product_detail_list." + index + ".serial_no" ))+" ")]):_vm._e()]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.serial_no),expression:"item.serial_no"}],staticClass:"form-control only-border-bottom",class:{
                                    'is-invalid': _vm.errors.has(
                                        'product_detail_list.' +
                                            index +
                                            '.serial_no'
                                    )
                                },attrs:{"type":"text"},domProps:{"value":(item.serial_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "serial_no", $event.target.value)}}}),(
                                    _vm.errors.has(
                                        'product_detail_list.' +
                                            index +
                                            '.serial_no'
                                    )
                                )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first( "product_detail_list." + index + ".serial_no" ))+" ")]):_vm._e()]),_c('td',[_c('Select',{attrs:{"placeholder":_vm.$t('select')},model:{value:(item.uom_id),callback:function ($$v) {_vm.$set(item, "uom_id", $$v)},expression:"item.uom_id"}},_vm._l((_vm.getUom(item.product_id)),function(uom){return _c('Option',{key:uom.uom_id,attrs:{"value":uom.uom_id,"label":uom.uom_name_en}},[_vm._v(" "+_vm._s(uom.uom_name_en)+" ")])}),1),(
                                    _vm.errors.has(
                                        'product_detail_list.' +
                                            index +
                                            '.uom_id'
                                    )
                                )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first( "product_detail_list." + index + ".uom_id" ))+" ")]):_vm._e()],1),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(item.quantity),expression:"item.quantity",modifiers:{"number":true}}],staticClass:"form-control only-border-bottom",class:{
                                    'is-invalid': _vm.errors.has(
                                        'product_detail_list.' +
                                            index +
                                            '.quantity'
                                    )
                                },attrs:{"type":"number","placeholder":"Qty"},domProps:{"value":(item.quantity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "quantity", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),(
                                    _vm.errors.has(
                                        'product_detail_list.' +
                                            index +
                                            '.quantity'
                                    )
                                )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first( "product_detail_list." + index + ".quantity" ))+" ")]):_vm._e()]),_c('td',[_c('textarea',{directives:[{name:"model",rawName:"v-model.number",value:(item.remarks),expression:"item.remarks",modifiers:{"number":true}}],staticClass:"form-control only-border-bottom",class:{
                                    'is-invalid': _vm.errors.has(
                                        'product_detail_list.' +
                                            index +
                                            '.remarks'
                                    )
                                },attrs:{"rows":"1","placeholder":"remarks"},domProps:{"value":(item.remarks)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "remarks", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(
                                    _vm.errors.has(
                                        'product_detail_list.' +
                                            index +
                                            '.remarks'
                                    )
                                )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first( "product_detail_list." + index + ".remarks" ))+" ")]):_vm._e()]),_c('td',[_c('a',{staticClass:"text-danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeItem(item)}}},[_c('i',{staticClass:"fa fa-trash"})])])])}),0)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-mt-3 tw-text-right"},[_c('ts-button',{staticClass:"tw-mr-2",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$emit('cancel'); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),(!_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("save")))]):_vm._e(),(_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onUpdate.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("update")))]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }